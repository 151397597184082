import React from 'react';
import './Marquee.css';
import amyraa from '../../../Assets/Images/OurClientsline/Group 18155.png';
import asianpaints from '../../../Assets/Images/OurClientsline/Group 678.png';
import gptw from '../../../Assets/Images/OurClientsline/image 51.png';
import oren from '../../../Assets/Images/OurClientsline/image 75.png';
import fikka from '../../../Assets/Images/OurClientsline/image 76.png';
import jags from '../../../Assets/Images/OurClientsline/image 211.png';
import anglealign from '../../../Assets/Images/OurClientsline/image 78.png';
import generalmanagerinstitute from '../../../Assets/Images/OurClientsline/image 210.png';
import greenstar from '../../../Assets/Images/OurClientsline/image 194.png';
import goodrej from '../../../Assets/Images/OurClientsline/image 188.png';
import ShillsProfessional from '../../../Assets/Images/OurClientsline/image 156.png';
import Karnawat from '../../../Assets/Images/OurClientsline/image 209.png';
import Genwe from '../../../Assets/Images/OurClientsline/image 77.png';
import MissionToCure from '../../../Assets/Images/OurClientsline/image 104.png';
import MansaMataFinance from '../../../Assets/Images/OurClientsline/image 212.png';
import Veekay from '../../../Assets/Images/OurClientsline/Group 1272628337.png';
import Faatang from '../../../Assets/Images/OurClientsline/image 163.png';
import SpProduct from '../../../Assets/Images/OurClientsline/image 129.png';
import Keynote from '../../../Assets/Images/OurClientsline/image 148.png';
import Vivaah from '../../../Assets/Images/OurClientsline/image 199.png';
import Poker from '../../../Assets/Images/OurClientsline/image 173.png'
import Greenlight from '../../../Assets/Images/OurClientsline/image 165.png'
import Oleo from '../../../Assets/Images/OurClientsline/image 185.png'
import SnekaerVally from '../../../Assets/Images/OurClientsline/Group 1272628338.png'
import Snergy from '../../../Assets/Images/OurClientsline/image 184.png'
import forumhardware from '../../../Assets/Images/OurClientsline/image 152.png'
import Terraglow from '../../../Assets/Images/OurClientsline/Group 18391.png'
import Centerac from '../../../Assets/Images/OurClientsline/image 170.png'
import Kcl from '../../../Assets/Images/OurClientsline/image 176.png'
import Jcc from '../../../Assets/Images/OurClientsline/jcc LOGO 2.png'
import HelloJweller from '../../../Assets/Images/OurClientsline/Group 3666.png'
import Greengati from '../../../Assets/Images/OurClientsline/image 135.png'
import NeoCell from '../../../Assets/Images/OurClientsline/Group 3733.png'
import PrimeJewller from '../../../Assets/Images/OurClientsline/Group 3654.png'





















function Marquee() {
  return (
    <div>
      <div className="marquee">
        <ul className="marquee__content scrollWala">
          <li>
            <img src={amyraa} alt="" width="80%"  />
          </li>
          <li>
            <img src={asianpaints} alt="" width="70%"  />
          </li>
          <li>
            <img src={gptw} alt="" width="80%"  />
          </li>
          <li>
            <img src={oren} alt="" width="80%"  />
          </li>
          <li>
            <img src={fikka} alt="" width="80%"  />
          </li>
          <li>
            <img src={anglealign} alt="" width="80%" />
          </li>
          <li>
            <img src={generalmanagerinstitute} alt="" width="80%"  />
          </li>
          <li>
            <img src={greenstar} alt="" width="80%"  />
          </li>
          <li>
            <img src={goodrej} alt="" width="100%"  />
          </li>
          <li>
            <img src={jags} alt="" width="80%"  />
          </li>
          <li>
            <img src={ShillsProfessional} alt="" width="80%"  />
          </li>
          <li>
            <img src={Karnawat} alt="" width="80%"  />
          </li>
          <li>
            <img src={Genwe} alt="" width="80%"  />
          </li>
          <li>
            <img src={MissionToCure} alt="" width="80%"  />
          </li>
          <li>
            <img src={MansaMataFinance} alt="" width="80%"  />
          </li>
          <li>
            <img src={Veekay} alt="" width="80%" />
          </li>
          <li>
            <img src={Faatang} alt="" width="80%"  />
          </li>
          <li>
            <img src={SpProduct} alt="" width="80%"  />
          </li>
          <li>
            <img src={Keynote} alt="" width="100%"  />
          </li>
          <li>
            <img src={Vivaah} alt="" width="80%"  />
          </li>
          <li>
            <img src={Poker} alt="" width="90%"  />
          </li>
          <li>
            <img src={Greenlight} alt="" width="90%"  />
          </li>
          <li>
            <img src={Oleo} alt="" width="90%"  />
          </li>
          <li>
            <img src={SnekaerVally} alt="" width="90%"  />
          </li>
          <li>
            <img src={Terraglow} alt="" width="90%"  />
          </li>
          <li>
            <img src={forumhardware} alt="" width="90%"  />
          </li>
          <li>
            <img src={Snergy} alt="" width="90%"  />
          </li>
          <li>
            <img src={Centerac} alt="" width="90%"  />
          </li>
          <li>
            <img src={Kcl} alt="" width="90%"  />
          </li>

          <li>
            <img src={Jcc} alt="" width="90%"  />
          </li>

          <li>
            <img src={HelloJweller} alt="" width="90%"  />
          </li>

          <li>
            <img src={Greengati} alt="" width="90%"  />
          </li>
          <li>
            <img src={NeoCell} alt="" width="90%"  />
          </li>
          
          <li>
            <img src={PrimeJewller} alt="" width="90%"  />
          </li>
          
          
          
         
         
        </ul>
        <ul className="marquee__content scrollWala">
        <li>
            <img src={amyraa} alt="" width="80%"  />
          </li>
          <li>
            <img src={asianpaints} alt="" width="70%"  />
          </li>
          <li>
            <img src={gptw} alt="" width="80%"  />
          </li>
          <li>
            <img src={oren} alt="" width="80%"  />
          </li>
          <li>
            <img src={fikka} alt="" width="80%"  />
          </li>
          <li>
            <img src={anglealign} alt="" width="80%" />
          </li>
          <li>
            <img src={generalmanagerinstitute} alt="" width="80%"  />
          </li>
          <li>
            <img src={greenstar} alt="" width="80%"  />
          </li>
          <li>
            <img src={goodrej} alt="" width="100%"  />
          </li>
          <li>
            <img src={jags} alt="" width="80%"  />
          </li>
          <li>
            <img src={ShillsProfessional} alt="" width="80%"  />
          </li>
          <li>
            <img src={Karnawat} alt="" width="80%"  />
          </li>
          <li>
            <img src={Genwe} alt="" width="80%"  />
          </li>
          <li>
            <img src={MissionToCure} alt="" width="80%"  />
          </li>
          <li>
            <img src={MansaMataFinance} alt="" width="80%"  />
          </li>
          <li>
            <img src={Veekay} alt="" width="80%" />
          </li>
          <li>
            <img src={Faatang} alt="" width="80%"  />
          </li>
          <li>
            <img src={SpProduct} alt="" width="80%"  />
          </li>
          <li>
            <img src={Keynote} alt="" width="100%"  />
          </li>
          <li>
            <img src={Vivaah} alt="" width="80%"  />
          </li>
          <li>
            <img src={Poker} alt="" width="90%"  />
          </li>
          <li>
            <img src={Greenlight} alt="" width="90%"  />
          </li>
          <li>
            <img src={Oleo} alt="" width="90%"  />
          </li>
          <li>
            <img src={SnekaerVally} alt="" width="90%"  />
          </li>
          
          <li>
            <img src={Terraglow} alt="" width="90%"  />
          </li>
          <li>
            <img src={forumhardware} alt="" width="90%"  />
          </li>
          <li>
            <img src={Snergy} alt="" width="90%"  />
          </li>
          <li>
            <img src={Centerac} alt="" width="90%"  />
          </li>
          <li>
            <img src={Kcl} alt="" width="90%"  />
          </li>
          <li>
            <img src={Jcc} alt="" width="90%"  />
          </li>

          <li>
            <img src={HelloJweller} alt="" width="90%"  />
          </li>

          <li>
            <img src={Greengati} alt="" width="90%"  />
          </li>
          <li>
            <img src={NeoCell} alt="" width="90%"  />
          </li>
            
          <li>
            <img src={PrimeJewller} alt="" width="90%"  />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Marquee;

